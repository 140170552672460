import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { HttpHelper } from '../../utils/http-helper';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { mergeMap, tap, finalize, filter, map, catchError } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { EndpointConfig } from 'src/app/config/endpoint-config';
import { Project, TagDefinition, TaggingColumn, DatasetInfo } from 'src/app/model/project/project';
import { DatasetObject, Dataset, ImageListAPIResponse } from 'src/app/model/dataset/dataset';
import { NonAnonymizedAlertResult } from 'src/app/model/alert/nonAnonymized';
import { RouterService } from 'src/app/service/router-service';
import { DialogService } from 'src/app/service/dialog-service';
import { ProjectConvertService } from '../../service/project/project-convert-service';
import { CommonUtils, SearchPageUtils } from 'src/app/utils/common';
import { UAC } from 'src/app/service/user/uac';
import { TemplateStatus } from 'src/app/model/template/templateStatus';
import { e } from '@angular/core/src/render3';
import { of, forkJoin } from 'rxjs';
import { MatDialogRef } from '@angular/material';
import { TemplateGroup, TemplateGroupList } from 'src/app/model/template/template';
import { udpImageResponse } from 'src/app/model/web-data';

@Component({
  selector: 'app-dataset-viewer',
  templateUrl: './dataset-viewer.component.html',
  styleUrls: ['./dataset-viewer.component.css']
})
export class DatasetViewerComponent implements OnInit {

  static CREATE_TEMPLATE_CONFIRM = (count: number, descripton: string, splitSize: number) => {
    let splitMessage = count > splitSize
      ? `Template Group分割サイズ: ${splitSize}<br>`
      : ''
    return `内容: ${descripton}<br>
            選択したデータセット: ${count}枚<br>
            ${splitMessage}
            <br>
            上記について、Template Groupを作成しますか？<br>`
  }
  static DESELECT_DATA_SET = () => {
    return `ロック済みのデータセットの選択を解除しました。`
  }
  static TAGGING_INFO = (endpoint: string, templateGroupIds: string[]) => {
    window.location.href
    let taggingUrls = templateGroupIds.map(x => endpoint + '/tag/web-tagging/template-group/' + x)
    return `作成したTemplate GroupのWeb Tagging URLは下記の通りです。<br>
            コピーして使用していただけます。<br>
            ${taggingUrls.join('<br>')}<br>
            <br>
            Status List画面へ遷移します。<br>`
  }
  static ALERT_NON_ANONYMIZED_DATASETS = (alertedCount: number, alertFailedDatasetIds: Array<string>) => {
    const alertFailedMessage = alertFailedDatasetIds ? `以下データセットのアラート通知に失敗しました。<br>
                                IDをコピーして運用担当者へご連絡ください。<br>
                                ${alertFailedDatasetIds.join('<br>')}` : ''
    return `アラート済み非匿名化画像: ${alertedCount}枚<br>
            ${alertFailedMessage}`
  }

  @ViewChild('viewContainer')
  viewContainer: ElementRef<HTMLDivElement>

  constructor(
    private http: HttpClient,
    private httpHelper: HttpHelper,
    private logger: NGXLogger,
    private endpointConfig: EndpointConfig,
    private dialog: DialogService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private routerService: RouterService,
    private projectConvertService: ProjectConvertService,
    private uac: UAC,
  ) { }

  private searchForm = {
    category: [],
    subclass: [],
    meta: []
  }

  private searchFormDefinition = {
    category: {
      type: Array,
      predicate: (x) => !CommonUtils.isEmpty(x)
    },
    subclass: {
      type: Array,
      predicate: (x) => !CommonUtils.isEmpty(x)
    },
    meta: {
      type: Array,
      predicate: (x) => !CommonUtils.isEmpty(x)
    }
  }

  private optionValues = {}

  private projectName: string;
  private project: Project
  private tagDefinitions: TagDefinition[] = []
  private taggingColumns: TaggingColumn[] = []
  private taggingGroup: string

  private categoryList: string[] = []
  private subclassList: string[] = []
  private metaList: string[] = []

  private datasets: Dataset[] = []
  private totalCount: number = 0;
  private displayDatasetList: Dataset[] = []
  private displayOffset: number = 0
  private displaySpan: number = 50


  private imgWidth: number = 150

  private lockMode: boolean = false
  private description: string = ''
  private splitSize: number = 200
  private lockCount: number = 0

  private propertyToTypeMap: { [key: string]: string } = {};
  private pathRegExp = new RegExp('\\[(.*)\\]')
  private urlRegExp = new RegExp('.*#')

  bboxLabelProperty = ''

  ngOnInit() {
    this.projectName = this.activatedRoute.snapshot.paramMap.get('projectName')

    let queryParams = this.activatedRoute.snapshot.queryParams

    this.dialog.loadingShow()
    this.getProject(this.projectName)
      .pipe(
        tap((project) => {
          this.project = project
          this.tagDefinitions = project.tagDefinition
          this.taggingColumns = project.taggingColumn
          this.taggingGroup = project.taggingGroup

          // meta検索用のプルダウン作成
          this.project.datasetInfo.meta.forEach((_meta) => {
            _meta.value.sort().forEach((val) => {
              this.metaList.push(_meta.key + ':' + val)
            })
          })

          // propertyとtypeのマッピングを作成
          this.taggingColumns.forEach(taggingColumn => {
            this.tagDefinitions.forEach(tagDefinition => {
              if (taggingColumn.definition == tagDefinition.name) {
                this.propertyToTypeMap[taggingColumn.property] = tagDefinition.type
              }
            })
          })

          // 検索フォームで使用するcategoryのリスト作成
          this.project.datasetInfo.category.forEach((val) => {
            this.categoryList.push(val.category)
          })

          // taggingColumnsにimageレベルのタグ付けのみ存在する場合、bboxLabelPropertyは設定しない。
          let itemTaggingColumns = this.taggingColumns.filter(x => x.taggingLevel == 'item')
          if (itemTaggingColumns.length > 0) {
            this.bboxLabelProperty = itemTaggingColumns[0].property
          }
        })
      )
      .subscribe(() => {

        // taggingPropertyの検索用のフォームを追加、プルダウンを作成
        this.addSearchFormAndDefinition()
        this.createOptionValues()
        // クエリパラメータをフォームにセットする。
        let conditions = SearchPageUtils.queryParamToConditions(this.searchFormDefinition, queryParams);
        SearchPageUtils.setSearchForm(this.searchFormDefinition, conditions, this.searchForm)

        this.search()
      }, (res) => {
        this.httpHelper.getDefaultErrorHandler()(res)
          .subscribe((_) => {
            this.doBack()
          })
      })
  }

  getProject(projectName: string) {
    let url = this.endpointConfig.riaApi.project.projectName.getUrl(projectName)
    let options = { params: new HttpParams().set('includeDatasetInfo', 'true') };
    return this.http.get<Project>(url, options)
  }

  addSearchFormAndDefinition() {
    for (let taggingColumn of this.taggingColumns) {
      this.searchForm[taggingColumn.property] = null

      if (this.propertyToTypeMap[taggingColumn.property] == 'select') {
        this.searchFormDefinition[taggingColumn.property] = {
          type: Array,
          predicate: (x) => !CommonUtils.isEmpty(x)
        }
      } else if (this.propertyToTypeMap[taggingColumn.property] != 'colorPicker') {
        this.searchFormDefinition[taggingColumn.property] = {
          type: String,
          predicate: (x) => !CommonUtils.isEmpty(x)
        }
      }
    }
  }

  search() {
    this.dialog.loadingShow()
    let conditions = SearchPageUtils.searchFormToConditions(this.searchFormDefinition, this.searchForm);

    //クエリパラメータが空の場合は検索をしない。
    if (!Object.keys(conditions).length) {
      this.datasets = []
      this.dialog.loadingHide()
      return
    }

    // 現在のURLに検索条件を追加
    this.router.navigate(this.activatedRoute.snapshot.url.map(x => x.path), {
      queryParams: conditions
    })

    // API用のクエリパラメータ作成
    let _params = new HttpParams()
    for (let key of Object.keys(conditions)) {
      _params = _params.set(key, conditions[key])
    }
    this.displayOffset = 0
    this.displayDatasetList = []

    this.getDatasets(this.projectName, { params: _params })
      .pipe(
        // map((data) => {
        //   data.datasets.forEach(dataset => {
        //     this.addClientPath(dataset)
        //     dataset.isSelected = false

        //     dataset.tags.forEach(x => {
        //       x._clientStyle = x.bbox && x.bbox.length == 4
        //         ? {
        //           position: 'absolute',
        //           top: x.bbox[1] * 100 + '%',
        //           left: x.bbox[0] * 100 + '%',
        //           width: (x.bbox[2] - x.bbox[0]) * 100 + '%',
        //           height: (x.bbox[3] - x.bbox[1]) * 100 + '%',
        //           border: '2px solid limegreen'
        //         } : null
        //     })
        //   })
        //   return data
        // }),
        mergeMap(data => {
          let documentIds = data.datasets.map((dataset) => dataset.src.split('?')[0].split('/').slice(-1)[0])
          let chunkSize = 21
          // e.g. documentIdsChunkList = [[xxxx, xxxx, xxxx, ...], [xxxx, xxxx, xxxx,...]...]
          let documentIdsChunkList: string[][] = documentIds.reduce((prevVal, _, i) => i % chunkSize ? prevVal : [...prevVal, documentIds.slice(i, i + chunkSize)], [])
          return forkJoin(documentIdsChunkList.map((documentIdsChunk) => this.getUDPImageInfo(documentIdsChunk)))
            .pipe(
              map((sourceUriResList) => {
                data.datasets.forEach((dataset, i) => {
                  let signedUrls: udpImageResponse['signedUrls']= []
                  sourceUriResList.forEach((sourceUriRes) => {
                    sourceUriRes.signedUrls.forEach((signedUrlObj) => {
                      signedUrls.push(signedUrlObj)
                    })
                  })
                  dataset.clientImagePath = signedUrls[i].signedUrl
                  dataset.isSelected = false

                  dataset.tags.forEach(x => {
                    x._clientStyle = x.bbox && x.bbox.length == 4
                      ? {
                        position: 'absolute',
                        top: x.bbox[1] * 100 + '%',
                        left: x.bbox[0] * 100 + '%',
                        width: (x.bbox[2] - x.bbox[0]) * 100 + '%',
                        height: (x.bbox[3] - x.bbox[1]) * 100 + '%',
                        border: '2px solid limegreen'
                      } : null
                  })
                })
                return data
              })
            )
        }),
        finalize(() => this.dialog.loadingHide())
      )
      .subscribe((data) => {

        this.datasets = data.datasets
        this.totalCount = data.totalCount
        this.lockCount = 0

        this.displayDatasetList = this.datasets.slice(this.displayOffset, this.displaySpan)
        this.displayOffset += this.displaySpan

      }, this.httpHelper.getDefaultErrorHandler())
  }

  onScroll(e: Event) {
    let target = <HTMLDivElement>e.target
    var position = target.scrollTop;
    var range = target.scrollHeight - target.clientHeight;

    if (position > (range - 200)) {
      this.displayDatasetList = this.displayDatasetList.concat(this.datasets.slice(this.displayOffset, this.displayOffset + this.displaySpan))
      this.displayOffset += this.displaySpan
    }
  }

  getDatasets(projectName: string, options: { params: HttpParams }) {
    let url = this.endpointConfig.riaApi.project.projectName.datasets.getUrl(projectName)
    return this.http.get<DatasetObject>(url, options)
  }

  getUDPImageInfo(documentIds: string[], options?: { params: HttpParams }) {
    let url = this.endpointConfig.riaApi.webData.udp.imageInfo.getUrl(documentIds)
    return this.http.get<udpImageResponse>(url, options)
  }

  // addClientPath(dataset: Dataset) {
  //   const headers = {
  //     'X-AUTH-SAML-TOKEN': this.uac.getUser().encodedData,
  //     'Access-Control-Allow-Origin': '*',
  //     'Content-Type': 'application/json'
  //   }

  //   this.http.get<ImageListAPIResponse>(dataset.src, { headers: headers }).subscribe((data) => {
  //     dataset.clientImagePath = data.url
  //   })

  //   this.http.get<ImageListAPIResponse>(dataset.zoomSrc, { headers: headers }).subscribe((data) => {
  //     dataset.clientOrgImagePath = data.url
  //   })

  //   return dataset
  // }

  // ダイアログ画面への処理
  displaySubclassSelection(selectedCategories: string[]) {

    // 選択されたcategoryに紐づくsubclassのリストを作成する。
    var _subclassList: string[] = []
    this.project.datasetInfo.category
      .filter(val => selectedCategories.includes(val.category))
      .forEach(val => {
        Array.prototype.push.apply(_subclassList, val.subclass);
      })

    this.subclassList = Array.from(new Set(_subclassList)).sort()

    // ダイアログを表示する。
    this.dialog.subclassSelection({
      selectedSubclass: this.subclassList.map((subclass) => {
        return {
          name: subclass,
          isSelected: this.searchForm.subclass.indexOf(subclass) >= 0
        }
      }),
      taggingPreset: this.project.taggingPreset
    }).subscribe(
      (selectedSubclasses: string[]) => {
        if (selectedSubclasses == undefined) {
          selectedSubclasses = []
        }
        this.searchForm.subclass = selectedSubclasses
      })
  }

  createOptionValues() {

    for (let taggingColumn of this.taggingColumns) {
      let tagDefinition: TagDefinition = null
      for (let _tagDefinition of this.tagDefinitions) {
        if (taggingColumn.definition == _tagDefinition.name) {
          tagDefinition = _tagDefinition
          break;
        }
      }

      if (tagDefinition.type == 'select') {
        let selectNode = this.projectConvertService.convertFromSelectNode(tagDefinition.selectNode)
        this.optionValues[taggingColumn.property] = selectNode
      } else if (tagDefinition.type == 'flag') {
        this.optionValues[taggingColumn.property] = ['', 'true', 'false']
      } else {
        this.optionValues[taggingColumn.property] = []
      }
    }
  }

  onClickImage(dataset: Dataset) {

    if (this.lockMode) {
      dataset.isSelected = !dataset.isSelected
      this.lockCount += dataset.isSelected
        ? 1 : -1
    } else {
      window.open(dataset.clientOrgImagePath)
    }
  }

  selectAll() {
    this.datasets.forEach(x => x.isSelected = true)
    this.lockCount = this.datasets.length
  }

  selectClear() {
    this.datasets.forEach(x => x.isSelected = false)
    this.lockCount = 0
  }

  resetSearchCondition() {
    this.searchForm = SearchPageUtils.getDefaultSearchForm(this.searchFormDefinition)
  }

  doInsert() {
    this.dialog.templateGroupDataInput()
      .pipe(
        filter(x => x.ok),
        tap(x => {
          this.description = x.description
          this.splitSize = x.splitSize
        }),
        mergeMap(x => {
          return this.dialog.confirm('Lock', DatasetViewerComponent.CREATE_TEMPLATE_CONFIRM(
            this.datasets.filter(x => x.isSelected).length, this.description, this.splitSize))
        }),
        filter(x => x),
        tap(() => this.dialog.loadingShow()),
        mergeMap(x => {
          let reqData = {}
          reqData['datasetIds'] = this.datasets.filter(x => x.isSelected).map(x => x._id)
          reqData['lockLevel'] = 'none'
          reqData['projectName'] = this.projectName
          return this.http.post(this.endpointConfig.riaApi.templates.json.postUrl(), reqData)
        }),
        mergeMap((x) => {
          let templateIds = x['templates'].map(template => template.templateId)
          let requests = CommonUtils.splitToSubset<string>(templateIds, this.splitSize).map((_templateIds, i) => {
            return this.http.post(this.endpointConfig.riaApi.templates.json.group.getUrl(), {
              projectName: this.projectName,
              description: `${this.description}-${i + 1}`,
              templateIds: _templateIds
            })
          })
          return forkJoin(requests)
        }),
        tap(() => this.dialog.loadingHide()),
        mergeMap((templateGroups: TemplateGroup[]) => {
          let templateGroupIds = templateGroups.map(x => x['_id'])
          let endpoint = window.location.href.match(this.urlRegExp)[0]
          return this.dialog.info('Tagging Info', DatasetViewerComponent.TAGGING_INFO(endpoint, templateGroupIds))
        }),
      ).subscribe(
        (x) => {
          // Status Listへ遷移する。
          this.router.navigate([`tag/project/${this.projectName}/status-list`], {
            queryParams: {
              description: this.description
            }
          })
          this.description
        },
        (res: HttpErrorResponse) => {
          if (res.status == 400 || res.status == 409) {
            var messages = <string[]>res.error.errors.map(e => e.message)

            this.dialog.error('Error', messages.join('<br>'))
              .subscribe(x => {
                // (例) errorDatasetIds = ['100000000000000000000001', '200000000000000000000001', ・・・]
                let errorDatasetIds = messages.join('<br>')
                  .match(this.pathRegExp)[1]
                  .split(',')
                  .map(x => x.trim())

                this.datasets
                  .filter(x => x.isSelected)
                  .filter(x => errorDatasetIds.indexOf(x._id) > -1)
                  .forEach(x => {
                    x.isSelected = false
                    x.isLock = true
                  })

                this.lockCount = this.datasets
                  .filter(x => x.isSelected).length
                this.dialog.info('Info', DatasetViewerComponent.DESELECT_DATA_SET())
              })
          } else {
            this.httpHelper.getDefaultErrorHandler()(res)
          }
        }
      )
  }

  moveScrollTop() {
    this.viewContainer.nativeElement.scrollTop = 0
  }

  zoomIn() {
    this.imgWidth += this.imgWidth <= 400 ? 50 : 0;
  }

  zoomOut() {
    this.imgWidth -= this.imgWidth <= 100 ? 0 : 50;
  }

  private EXCLUDE_QUERY_PARAM = true
  doBack() {
    let previousUrl = this.routerService.getPreviousUrl(this.EXCLUDE_QUERY_PARAM)
    if (previousUrl.split('/').slice(0, 3).join('/') == '/tag/web-tagging') {
      this.router.navigate([`tag/project/${this.projectName}/detail`])
    } else if (this.routerService.getCurrentUrl(this.EXCLUDE_QUERY_PARAM) === previousUrl) {
      this.router.navigate(['tag/home'])
    } else {
      this.router.navigateByUrl(this.routerService.getPreviousUrl(this.EXCLUDE_QUERY_PARAM))
    }
  }

  nonAnonymizationAlert() {
    const reqBody = {
      "datasetIds": this.datasets.filter(dataset => dataset.isSelected).map(dataset => dataset._id),
      "omitAlert": false
    }

    this.dialog.loadingShow()
    this.http.post(
      this.endpointConfig.riaApi.alert.nonAnonymized.postUrl(this.projectName),
      reqBody
    ).pipe(
      finalize(() => this.dialog.loadingHide())
    ).subscribe(
      (res: NonAnonymizedAlertResult) => {
        this.dialog.info(
          'Alert non anonymized datasets',
          DatasetViewerComponent.ALERT_NON_ANONYMIZED_DATASETS(res.alertedCount, res.failedDatasetIds))
      },
      (res: HttpErrorResponse) => {
        if (res.status == 400) {
          const messages: Array<string> = res.error.errors.map(e => e.message)
          this.dialog.error('Error', messages.join('<br>')).subscribe(
            () => console.log('error confirmed')
          )
        } else {
          this.httpHelper.getDefaultErrorHandler()(res)
        }
      }
    )
  }
}
