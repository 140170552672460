import { User } from "src/app/interface/user";
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injector, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LocalStorageService } from "ngx-store";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { EndpointConfig } from 'src/app/config/endpoint-config';

@Injectable()
export class UAC {

  private static loginUser: User;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private endpointConfig: EndpointConfig
  ) {
    this._restore()
  }

  private _restore() {
    UAC.loginUser = this.getUser()
  }

  public setUser(user: User, isRetention: boolean) {
    UAC.loginUser = user
    if (isRetention) {
      this.localStorageService.set('loginUser', user)
    }
  }

  public getUser(): User {
    return UAC.loginUser
      || this.localStorageService.get('loginUser')
  }

  public isLogged(): boolean {
    var user = this.getUser()
    if (user) {
      return user.tokenExpirationDate > (new Date().getTime() / 1000)
    }
    return false
  }

  public login(userId: string, pass: string): Observable<User> {
    return this.http.post<User>(this.endpointConfig.riaApi.users.token.getUrl(), {
      userId: userId,
      password: pass
    }, {
        headers: {
          "content-type": 'application/json'
        }
      })
  }

  public sso(path: string = null){
    const successPath = path ? `?path=${path}` : ''
    window.location.replace(this.endpointConfig.riaApi.users.saml.login.getUrl() + successPath)
  }

  public logout(): void {
    UAC.loginUser = null
    this.localStorageService.clear()
  }
}

@Injectable()
export class AuthorizationGuard implements CanActivate {
  private previousUrl: string

  constructor(
    private router: Router,
    private uac: UAC) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.uac.isLogged()) {
      this.router.navigate(['tag/login'], {
        queryParams: {
          next: state.url
        }
      })
      return false
    }

    const currentUserRole = this.uac.getUser().role
    const allowedRoles: Array<string> = route.data["allowdRoles"]
    if (!allowedRoles.includes(currentUserRole)) {
      this.router.navigate(['error'], {
        queryParams: {
          returnUrl: this.previousUrl,
          errorMsg: 'Invalid Role'
        }
      })
      return false
    }

    this.previousUrl = state.url
    return true;
  }
}


@Injectable()
export class AuthorizationTokenInterceptor implements HttpInterceptor {

  static SKIP_AUTHORIZATION_HEADER_KEY = 'X-Client-Exclude-Authorization-Header'

  constructor(public uac: UAC, public injector: Injector) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let isSkip = request.headers.has(AuthorizationTokenInterceptor.SKIP_AUTHORIZATION_HEADER_KEY)
    let _headers = isSkip
      ? request.headers.delete(AuthorizationTokenInterceptor.SKIP_AUTHORIZATION_HEADER_KEY)
      : request.headers

    request = request.clone({
      headers: _headers
    })

    if (!isSkip && this.uac.isLogged()) {
      let headers = request.headers.append('Authorization', `Bearer ${this.uac.getUser().token}`)
      headers = headers.append('X-AUTH-SAML-TOKEN', `${this.uac.getUser().encodedData}`)
      headers = headers.append('X-Request-Date', new Date().toISOString())
      headers = headers.append('X-Current-URL', window.location.href)

      request = request.clone({
        headers: headers
      });
    }
    return next.handle(request);
  }
}
