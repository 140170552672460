import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";

@Injectable()
export class EndpointConfig {

  riaApi = {
    imageUrl: environment.ria.endpoint + 'collections/',

    collections: {
      prefixes: {
        getUrl: () => {
          return environment.ria.endpoint + 'collections/s3/prefixes';
        }
      }
    },
    tags: {
      manual: {
        getUrl: () => {
          return environment.ria.endpoint + `static/assets/manual/TaggingTool-manual.pptx`
        }
      },
      definitions: {
        getUrl: (author) => {
          return environment.ria.endpoint + `runways/tags/definitions/${author}`
        }
      },
      brands: {
        getUrl: (store, collection) => {
          return environment.ria.endpoint + `runways/tags/brands/${store}/${collection}`
        }
      },
      templates: {
        getUrl: (format?) => {
          var suffix = ''
          if (format) {
            suffix = `.${format}`
          }

          return environment.ria.endpoint + `runways/tags/templates${suffix}`
        },
        file: {
          getUrl: (templateId: string, format: string) => {
            return environment.ria.endpoint + `runways/tags/templates/${templateId}/${format}`
          }
        }
      },
      templatesExcel: {
        getUrl: () => {
          return environment.ria.endpoint + 'runways/tags/templates.excel'
        }
      },
      qlikview: {
        getUrl: (format: string) => {
          return environment.ria.endpoint + `runways/tags.qlikview/${format}`
        }
      },
      getUrl: () => {
        return environment.ria.endpoint + 'runways/tags'
      }
    },
    search: {
      getUrl: () => {
        return environment.ria.endpoint + 'runways/search'
      }
    },
    colorDistributions: {
      getUrl: () => {
        return environment.ria.endpoint + 'runways/colorDistributions'
      },
      definitions: {
        getUrl: () => {
          return environment.ria.endpoint + 'runways/colorDistributions/definitions'
        }
      }
    },
    project: {
      getUrl: () => {
        return environment.ria.endpoint + 'tagging/projects'
      },
      dataset: {
        img: {
          getUrl: (projectName: string, datasetId: string, propertyName: 'src' | 'zoomSrc') => {
            return environment.ria.endpoint + `tagging/projects/${projectName}/datasets/${datasetId}/${propertyName}.img`
          }
        }
      },
      projectName: {
        getUrl: (projectName) => {
          return environment.ria.endpoint + `tagging/projects/${projectName}`
        },
        qlikview: {
          getUrl: (projectName) => {
            return environment.ria.endpoint + `tagging/projects/${projectName}/qlikview`

          }
        },
        templates: {
          getUrl: (projectName) => {
            return environment.ria.endpoint + `tagging/projects/${projectName}/templates`
          },
          group: {
            getUrl: (projectName) => {
              return environment.ria.endpoint + `tagging/projects/${projectName}/templates/group`
            }
          }
        },
        templateId: {
          getUrl: (projectName: string, templateId: string) => {
            return environment.ria.endpoint + `tagging/projects/${projectName}/${templateId}`
          }
        },
        datasets: {
          getUrl(projectName) {
            return environment.ria.endpoint + `tagging/projects/${projectName}/datasets`
          },
          postUrl(projectName) {
            return environment.ria.endpoint + `tagging/projects/${projectName}/datasets`
          },
          // Web tagging submit url
          putUrl: (projectName) => {
            return environment.ria.endpoint + `tagging/projects/${projectName}/datasets`
          }
        },
        taggingGroup: {
          putUrl: (projectName) => {
            return environment.ria.endpoint + `tagging/projects/${projectName}/taggingGroup`
          }
        }
      },
      jenkins:{
        getUrl: (projectName: string) => {
          return environment.ria.endpoint + `jenkins/${projectName}`
        }
      },
    },
    alert:{
      nonAnonymized: {
        postUrl: (projectName) => {
          return environment.ria.endpoint + `alert/nonAnonymized/projects/${projectName}/datasets`
        }
      }
    },
    request: {
      deleteDataset: {
        postUrl: (projectName) => {
          return environment.ria.endpoint + `request/delete/projects/${projectName}/datasets`
        },
        getUrl: () => {
          return environment.ria.endpoint + `request/delete/histories`
        },
        putUrl: (historyId) => {
          return environment.ria.endpoint + `request/delete/histories/${historyId}`
        }
      }
    },
    templates: {
      getUrl: (templateId) => {
        return environment.ria.endpoint + `tagging/templates/${templateId}`
      },
      json: {
        postUrl: () => {
          return environment.ria.endpoint + `tagging/templates.json`
        },
        getUrl: (templateId: string) => {
          return environment.ria.endpoint + `tagging/templates.json/${templateId}`
        },
        group: {
          getUrl: () => {
            return environment.ria.endpoint + `tagging/templates.json/group`
          },
          templateGroupId: {
            getUrl: (templateGroupId) => {
              return environment.ria.endpoint + `tagging/templates.json/group/${templateGroupId}`
            }
          }
        },
        dataset: {
          getUrl: (templateId: string, datasetId: string) => {
            return environment.ria.endpoint + `tagging/templates.json/${templateId}/${datasetId}`
          }
        },
        editing: {
          getUrl: (templateId: string, datasetId: string) => {
            return environment.ria.endpoint + `tagging/templates.json/${templateId}/${datasetId}/editing`
          }
        },
        recommend: {
          getUrl: (templateId: string, datasetId: string) => {
            return environment.ria.endpoint + `tagging/templates.json/${templateId}/${datasetId}/recommend`
          }
        }
      }
    },
    users: {
      token: {
        getUrl: () => {
          return environment.ria.endpoint + 'users/token'
        }
      },
      me: {
        getUrl: () => {
          return environment.ria.endpoint + 'users/me'
        }
      },
      saml: {
        login: {
          getUrl: () => {
            return environment.ria.endpoint + 'users/saml/login'
          }  
        }
      },
    },
    webData: {
      udp: {
        imageInfo: {
          getUrl: (documentIds: string[]) => {
            return environment.ria.endpoint + `webData/udp/imageInfo/${documentIds.join(',')}`
          }
        }
      }
    }    
  }
}
